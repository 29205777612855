<template>
	<v-container>
		<a-request-password-reset-card />
	</v-container>
</template>

<script>
import ARequestPasswordResetCard from '@/components/RequestPasswordResetCard';

export default {
	components: {
		ARequestPasswordResetCard,
	},
};
</script>

<style></style>

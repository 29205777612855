<template>
	<v-card>
		<v-card-title>Återställ lösenord</v-card-title>
		<v-card-text>
			Ange den e-postadress som du är registrerad med
		</v-card-text>

		<v-form @submit="submit">
			<v-container class="pa-4">
				<a-form-input>
					<v-text-field
						v-model="email"
						:error-messages="emailErrors"
						label="E-postadress"
						type="text"
						required
					/>
				</a-form-input>
			</v-container>

			<v-card-actions>
				<v-btn :to="{ name: 'login' }" text>
					Tillbaka
				</v-btn>
				<v-flex grow />
				<v-btn
					:loading="loading"
					:disabled="loading"
					type="submit"
					color="secondary"
				>
					Bekräfta
				</v-btn>
			</v-card-actions>
		</v-form>
	</v-card>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import AFormInput from '@/components/FormInput';
import { REQUEST_PASSWORD_RESET } from '@/graphql';

export default {
	components: {
		AFormInput,
	},
	data: () => ({
		loading: false,
		email: '',
	}),
	computed: {
		emailErrors() {
			const errors = [];
			if (!this.$v.email.$dirty) return errors;
			if (!this.$v.email.required) {
				errors.push('E-postadress krävs');
			}

			if (!this.$v.email.email) {
				errors.push('Måste vara en giltig e-postadress');
			}

			return errors;
		},
	},
	methods: {
		submit(event) {
			event.preventDefault();
			this.$v.$touch();
			if (!this.$v.$invalid) this.requestReset();
		},
		async requestReset() {
			const email = this.email.toLowerCase();
			this.loading = true;

			this.$apollo
				.mutate({
					mutation: REQUEST_PASSWORD_RESET,
					variables: {
						input: {
							email,
						},
					},
				})
				.then(() => {
					this.$toast.success(
						'En återställningslänk har skickats till din e-postadress',
					);
					this.$router.push({ name: 'login' });
				})
				.catch(error => {
					this.email = email;
					this.loading = false;
					this.$v.$reset();
					this.$toast.error(error.graphQLErrors[0].message);
				});
		},
	},
	validations: {
		email: {
			required,
			email,
		},
	},
};
</script>

<style></style>
